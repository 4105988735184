/* eslint-disable no-unused-vars */
//Authentication
import { createSlice } from '@reduxjs/toolkit';

let logoutTimer;

const initialAuthState = {
  token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
  isAuthenticated: !!localStorage.getItem('token'),
  expirationTime: +localStorage.getItem('expiresIn'),
  name: localStorage.getItem('name'),
  email: localStorage.getItem('email'),
  personality: localStorage.getItem('personality'),
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    logoutHandler(state, action) {
      state.token = null;
      state.isAuthenticated = false;
      state.expirationTime = null;
      localStorage.removeItem('token');
      localStorage.removeItem('expiresIn');
      localStorage.removeItem('name');
      localStorage.removeItem('email');
      localStorage.removeItem('personality');
      if (logoutTimer) clearInterval(logoutTimer);
      window.location.reload();
    },
    loginHandler(state, action) {
      state.expirationTime = action.payload.expiresIn;
      state.token = action.payload.token;
      state.isAuthenticated = !!state.token;
      localStorage.setItem('token', state.token);
      localStorage.setItem('expiresIn', state.expirationTime);
      localStorage.setItem('name', action.payload.name);
      localStorage.setItem('email', action.payload.email);
      localStorage.setItem('personality', action.payload.personality);
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.personality = action.payload.personality;
    },
    setPersonality(state, action) {
      state.personality = action.payload.personality;
      localStorage.setItem('personality', action.payload.personality);
    },
    setToken(state, action) {
      state.token = action.payload;
      localStorage.setItem('token', state.token);
    },
    setExpirationDate(state, action) {
      state.expirationTime = action.payload;
      localStorage.setItem('expiresIn', state.expirationTime);
    },
  },
});

export const authActions = authSlice.actions;

// todo: add autologout

// export reducer
export default authSlice.reducer;
